$(document).ready(function () {
    // $(window).load(function() {
    //     setTimeout(function() {
    //         $('body').css('overflow', 'auto');
    //         $('.preloader').fadeOut('slow');
    //     }, 100);
    // });


    App.init();
    setTimeout(function () {
        $('body').css('overflow', 'auto');
        $('.preloader').fadeOut('slow');
    }, 100);
    // $('.timetable').cardtable();

    $(document).on('click', '#supportTrigger .trigger-font-family', function (e) {
        yaCounter22961791.reachGoal('zadatvopros');
        return true;
    });
});
App.showLottery = false;
App.init = function () {
    App.hamburger();
    App.table_adaptive();
    App.slider_height();
    App.sliders();
    App.tabs();
    App.responsiveTable();
    App.timetable();
    App.modalLinks();
    // App.disableActiveLink();
    App.panoHandler.init();
    // App.windowSize();
    App.lotteryModalAction();
    // App.modalSpecialOffer();
};

App.hamburger = function () {
    $('.hamburger').click(function () {
        $(this).toggleClass('closed-ham');
        $('.menu-container').toggleClass('activated');
    });
};


App.slider_height = function () {
    $(window).on('resize load', function () {
        if ($(window).width() >= '1200') {
            var windowHeight = $(window).height();
            var headerMenuHeight = $('.header').height() + $('.menu-container').height();
            var sliderHeight = windowHeight - headerMenuHeight;
            windowWidth = $(window).width();
            var ratioSlider = windowWidth / sliderHeight;
            if (ratioSlider > 2.6) {
                sliderHeight = windowWidth / 2.6;
            }
            $('.main-slider .slick-slide').css('max-height', sliderHeight);
        }
    });
};

App.table_adaptive = function () {

    $(window).on('load resize', windowSize);

    function windowSize() {
        if ($(window).width() <= '768') {
            var curDate = new Date;
            var dayNumber;
            dayNumber = curDate.getDay();
            if (dayNumber == 0) {
                dayNumber = 7;
            }
            var TH = $('.timetable-dates.tabs-table__tabs').find('th');
            var curTH = "tabs-table__tab tabs-table__col-header_" + dayNumber;
            // $('.tabs-table__tabs th').removeClass('currentDate');

            if ($(".tabs-table__tabs").find('.currentDate').length == 0) {
                $.each(TH, function () {
                    if ($(this).attr('class') === curTH) {
                        $(this).addClass('currentDate');
                    }
                });
            }

            $('.timetable tbody tr td:not(:nth-child(' + dayNumber + '))').css("display", "none");
            $('.timetable tbody tr td:nth-child(' + dayNumber + ')').css("display", "table-cell");

            $('.tabs-table__tabs th').click(function () {
                $('.tabs-table__tabs th').removeClass('currentDate');
                $(this).addClass('currentDate');

                var clickTH = $(this).attr('class');
                var num = Number(clickTH.replace(/\D+/g, ""));

                $('.timetable tbody tr td:not(:nth-child(' + num + '))').css("display", "none");
                $('.timetable tbody tr td:nth-child(' + num + ')').css("display", "table-cell");
            });
        } else {
            $('.timetable tbody tr td').css("display", "table-cell");
            $('.tabs-table__tabs th').removeClass('currentDate');
        }
    }
};


App.sliders = function () {
    var mainSwiper = new Swiper('.main-slider, .video-slides', {
        containerModifierClass: '',
        direction: 'horizontal',
        autoplay: 7000,
        loop: true,
        slidesPerView: 'auto',
        slidesPerColumn: 1,
        loopFillGroupWithBlank: true,
        spaceBetween: 0,
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
    });

    var gallerySwiper = new Swiper('.gallery-slider', {
        direction: 'horizontal',
        autoplay: 5000,
        loop: true,
        slidesPerView: 'auto',
        slidesPerColumn: 1,
        loopFillGroupWithBlank: true,
        spaceBetween: 0,
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
    });

    var mySwiper = new Swiper('.about-slider', {
        direction: 'horizontal',
        // autoplay: 5000,
        loop: true,
        slidesPerView: 1,
        slidesPerColumn: 1,
        spaceBetween: 0,
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
    });

    var flampSlider = new Swiper('.flamp .items', {
        direction: 'horizontal',
        autoplay: 5000,
        speed: 500,
        loop: true,
        slidesPerView: 1,
        slidesPerColumn: 1,
        spaceBetween: 50,
    });
};
App.tabs = function () {
    $('.jstabs a').click(function (e) {
        e.preventDefault();
        $(this).tab('show');
    });
};
App.responsiveTable = function () {
    $("table.responsive-table.auto-headers").each(function () {
        var table = $(this);
        var labels = [];
        var css_array = [];
        $("th", table).each(function () {
            labels.push($(this).text().replace(/\n/gi, "").replace(/\r/gi, ""));
        });
        if (!table.attr("id")) {
            table.attr("id", "id-" + new Date().getTime());
        }
        var id = table.attr("id");
        for (var i in labels) {
            css_array.push("#" + id + " td:nth-child(" + (parseInt(i, 10) + 1) + "):before {content: '" + labels[i] + ":';}");
        }
        $("body").append("<style type='text/css'>" + css_array.join("") + "</style>");
    });
};
App.timetable = function () {
    $(".tabs-table").each(function () {
        var $table = $(this);
        $(".tabs-table__tab", $table).click(function () {
            var col = $(this).data("show-col");
            numTableActive = col;
            $table.attr("data-visible-col", col);
        });
    });
    $(".open-timetable-popup").click(function () {
        var $timetable = $(this),
            $popup = $timetable.find(".popup"),
            $openTimetable = $(".timetable-popup-opened");
        $openTimetable.find(".popup").hide();
        $openTimetable.removeClass("timetable-popup-opened");
        $timetable.addClass("timetable-popup-opened");
        $popup.show();
    });
    $(document).on('click', ".open-timetable-popup .close", function () {
        $(this).parent(".popup").hide();
        $(".timetable-popup-opened").removeClass("timetable-popup-opened");
    });
    $(window).on('load scroll', function () {

        var $table = $(".tabs-table");
        if ($table.length != 0) {
            if ($(this).scrollTop() > $table.offset().top) {
                $table.addClass("fixed-header");
            } else {
                $table.removeClass("fixed-header");
            }
        }
    });
};
App.callbackSendForm = function (form, data, hasError) {
    if (hasError) {
        return false;
    }
    var formVar = $(form),
        button = formVar.find('button');
    $.ajax({
        method: 'POST',
        url: form.attr('action'),
        data: form.serialize(),
        beforeSend: function () {
            button.button('loading');
        },
        complete: function () {
            button.button('reset');
        },
        success: function (data) {
            if (form.data('goal').length) {
                form.data('goal').split(/[ ,]+/).forEach(function (elem, index) {
                    try {
                        yaCounter22961791.reachGoal(elem);
                    } catch (e) {
                        console.log(e);
                    }

                });
            }
            var type = data.result ? 'success' : 'danger';
            if (data.result) {
                formVar.find('[id *= "_em_"]').text('').hide().parent('.form-group').removeClass('has-error').addClass('has-success');
                $('#notifications').notify({message: {text: data.data}, type: type}).show();
                form.trigger('reset');
                $('#modal').modal('hide');
                window.location.replace("/thankyou");
            } else {
                $.each(data.data, function (key, val) {
                    if (key == "phone") {
                        formVar.find('[id *= "_' + key + '_modal_em_"]').text(val).show().parent('.form-group').removeClass('has-success').addClass('has-error');
                    }
                    formVar.find('[id *= "_' + key + '_em_"]').text(val).show().parent('.form-group').removeClass('has-success').addClass('has-error');
                });
            }
        }
    });
    return false;
};
App.modalLinks = function () {
    $('[data-target][data-url]').on('click', function (e) {
        e.preventDefault();
        var url = $(this).data('url'),
            modal = $(this).data('target'),
            addClass = $(this).data('class'),
            data = {};
        data[yupeTokenName] = yupeToken;
        if ($(this).data('data') != undefined) {
            data = $.extend(data, $(this).data('data'));
        }
        App.showLottery = true;
        $.ajax({
            type: 'post',
            url: url,
            data: data,
            dataType: 'html',
            success: function (data) {
                if (data) {
                    $('#modal .modal-content').html(data);
                    $('#modal').modal('show');
                    $('#modal').addClass(addClass);
                    if ($('#modal').find('.no-lottery').length > 0) {
                        App.showLottery = false;
                    }
                }
            },
            error: function (data) {
            }
        });
    });
    $('#modal').on('hidden.bs.modal', function () {
        $('#modal .modal-content').off();
        $('#modal .modal-content').html('');
        $('#modal').removeClass();
        $('#modal').addClass('modal fade');
    });
};
App.disableActiveLink = function () {
    $('body').on('click', '.active > a, a.active', function (e) {
        e.preventDefault();
    });
};
App.panoHandler = {
    class_pano: '.pano',
    settings: {
        onstart: 'startup();set(skin_settings.showinghelp,false);'
    },
    init: function () {
        var self = this;
        if ($(self.class_pano)) {
            $(self.class_pano).each(function (index) {
                if ($(self.class_pano).is('[pano]')) {
                    var pano = $(this).attr('pano');
                    embedpano({
                        target: 'krpano',
                        swf: pano,
                        height: '800px',
                        width: '100%',
                        wmode: 'opaque',
                        flash: 'only',
                        passQueryParameters: true,
                        vars: self.settings
                    });
                }
            });
        }
    },
}
// App.windowSize = function () {
//     $(window).on('load resize', function () {
//         if ($(window).width() <= '1024'){
//             $('.main-slider').addClass('is-mobile');
//         } else {
//             $('.main-slider').removeClass('is-mobile');
//         }
//     });
// };
App.lotteryModalAction = function () {
    $('#modal').on('hidden.bs.modal', function () {
        if (App.showLottery) {
            if (!$('#modal').hasClass('lottery')) {
                var data = {};
                data[yupeTokenName] = yupeToken;
                App.showLottery = false;
                $.ajax({
                    type: 'get',
                    url: '/site/lotteryModal',
                    data: data,
                    dataType: 'html',
                    success: function (data) {
                        if (data) {
                            $('#modal .modal-content').html(data);
                            $('#modal').modal('show');
                            $('#modal').addClass('lottery');
                        }
                    },
                    error: function (data) {
                    }
                });
            }
        }
    });
};

// App.modalSpecialOffer = function () {
//     $('.btn-get').on('click', function (e) {
//         e.preventDefault();
//         var url = '/site/priceModal2',
//             modal = '#modal',
//             addClass = $(this).data('class'),
//             data = {};
//         data[yupeTokenName] = yupeToken;
//         if ($(this).data('data') != undefined) {
//             data = $.extend(data, $(this).data('data'));
//         }
//         $.ajax({
//             type: 'post',
//             url: url,
//             data: data,
//             dataType: 'html',
//             success:function(data){
//                 if(data) {
//                     $('#modal .modal-content').html(data);
//                     $('#modal').modal('show');
//                     $('.modal-dialog').addClass('special-offer-modal');
//                 }
//             },
//             error: function(data) {
//             }
//         });
//     });
//     $('#modal').on('hidden.bs.modal', function () {
//         $('#modal .modal-content').off();
//         $('#modal .modal-content').html('');
//         $('#modal').removeClass();
//         $('#modal').addClass('modal fade');
//     });
// };
